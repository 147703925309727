import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicText } from '@prismicio/react'

export const TheArtists = ({ slice }) => {
  const less = slice.primary.read_less || 'Read Less'
  const more = slice.primary.read_more || 'Read More'
  function readMore(text) {
    var [showMore, setShowMore] = useState(false)
    return (
      <p>
        {showMore ? text : text.substring(0, 380)}
        <button className="btn" onClick={() => setShowMore(!showMore)}>
          {showMore ? ' ...' + less : '...' + more}
        </button>
      </p>
    )
  }
  return (
    <section className="the_artists">
      <div className="Container">
        <div className="artists_grid">
          <div className="half_grid_item">
            <PrismicRichText field={slice.primary.header.richText} />
            <PrismicRichText field={slice.primary.text.richText} />
          </div>
          {slice.items.map((galleryItem, index) => (
            <div className="full_grid_item">
              <GatsbyImage
                image={galleryItem.item_image.gatsbyImageData}
                alt={galleryItem.item_image.alt || ''}
                className="artist_image"
              />
              <PrismicRichText field={galleryItem.item_header.richText} />

              <div className="bio">{readMore(galleryItem.item_text.text)}</div>

              <div className="links">
                <a
                  className="amazon"
                  target="_blank"
                  rel="noopener"
                  aria-label="Amazon Music Link"
                  href={galleryItem.amazon_link.url}
                />
                <a
                  className="apple"
                  target="_blank"
                  rel="noopener"
                  aria-label="Apple Music Link"
                  href={galleryItem.apple_link.url}
                />
                <a
                  className="spotify"
                  target="_blank"
                  rel="noopener"
                  aria-label="Spotify Music Link"
                  href={galleryItem.spotify_link.url}
                />
              </div>
            </div>
          ))}
          <div className="half_grid_item">
            <GatsbyImage
              image={slice.primary.image?.gatsbyImageData}
              alt=""
              className="grid_image"
              role="presentation"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyTheArtists on PrismicHomepageDataBodyTheArtists {
    primary {
      header {
        richText
      }
      text {
        richText
      }
      read_more
      read_less
      image {
        url
        alt
        gatsbyImageData
      }
    }
    items {
      item_image {
        url
        alt
        gatsbyImageData(width: 358)
      }
      item_header {
        richText
      }
      item_text {
        richText
        text
      }
      amazon_link {
        url
      }
      apple_link {
        url
      }
      spotify_link {
        url
      }
    }
  }
  fragment SongwriterAiPageDataBodyTheArtists on PrismicSongwriterAiPageDataBodyTheArtists {
    primary {
      header {
        richText
      }
      text {
        richText
      }
      read_more
      read_less
      image {
        url
        alt
        gatsbyImageData
      }
    }
    items {
      item_image {
        url
        alt
        gatsbyImageData(width: 358)
      }
      item_header {
        richText
      }
      item_text {
        richText
        text
      }
      amazon_link {
        url
      }
      apple_link {
        url
      }
      spotify_link {
        url
      }
    }
  }
  fragment SongwriterAiHubDataBodyTheArtists on PrismicSongwriterAiHubDataBodyTheArtists {
    primary {
      header {
        richText
      }
      text {
        richText
      }
      read_more
      read_less
      image {
        url
        alt
        gatsbyImageData
      }
    }
    items {
      item_image {
        url
        alt
        gatsbyImageData(width: 358)
      }
      item_header {
        richText
      }
      item_text {
        richText
        text
      }
      amazon_link {
        url
      }
      apple_link {
        url
      }
      spotify_link {
        url
      }
    }
  }
`
