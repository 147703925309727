import { Hero } from './Hero'
import { VideoAndText } from './VideoAndText'
import { TheArtists } from './TheArtists'
import { HowItWorks } from './HowItWorks'
import { Prizes } from './Prizes'
import { Teacher } from './Teacher'
import { CourseOutline } from './CourseOutline'
import { Newsletter } from './Newsletter'
import { Sponsors } from './Sponsors'

import { FaqSection } from './FaqSection'

import { TrainingTab } from './TrainingTab'

import { ImageChecklist } from './ImageChecklist'

import { AudioSection } from './AudioSection'
import { CodeSection } from './CodeSection'
import { EmphasisSection } from './EmphasisSection'
import { ImageText } from './ImageText'
import { TextSection } from './TextSection'
import { VideoSection } from './VideoSection'
import { NotesSection } from './NotesSection'

import { PageTextSection } from './PageTextSection'
import { Button } from './Button'
import { CurriculumSection } from './CurriculumSection'

import { JudgeCardGrid } from './JudgeCardGrid'
import { WinnerCards } from './WinnerCards'

import { LyricsGeneratorSlice } from './LyricsGenerator'

import { StoryCard } from './StoryCards'

export const components = {
  hero: Hero,
  video_and_text: VideoAndText,
  the_artists: TheArtists,
  how_it_works: HowItWorks,
  prizes: Prizes,
  teacher: Teacher,
  course_outline: CourseOutline,
  newsletter: Newsletter,
  sponsors: Sponsors,
  faq_section: FaqSection,
  training_tab: TrainingTab,
  image_checklist: ImageChecklist,
  audio_section: AudioSection,
  code_section: CodeSection,
  emphasis_section: EmphasisSection,
  image_text: ImageText,
  text_section: TextSection,
  page_text_section: PageTextSection,
  notes_section: NotesSection,
  video_section: VideoSection,
  button: Button,
  curriculum_section: CurriculumSection,
  judge_card_grid: JudgeCardGrid,
  winner_cards: WinnerCards,
  lyrics_generator: LyricsGeneratorSlice,
  story_card: StoryCard,
}
