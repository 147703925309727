import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { asText } from '@prismicio/helpers'

export const StoryCard = ({ slice }) => {
  const [expandedContent, setExpandedContent] = useState({})

  const toggleContent = (index) => {
    setExpandedContent((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }
  function hasImage(image) {
    if (image) {
      return (
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt || ''}
          className="WinnerCards___image"
        />
      )
    } else return null
  }

  const handleCopyText = (item) => {
    const textToCopy = [
      asText(item.name?.richText) || '',
      asText(item.main_details?.richText) || '',
      asText(item.bio?.richText) || '',
    ]
      .filter((text) => text)
      .join('\n\n')

    navigator.clipboard.writeText(textToCopy)
    document
      .getElementsByClassName('copiedMessage')[0]
      .classList.remove('hidden')
    document.getElementsByClassName('defaultMessage')[0].classList.add('hidden')

    setTimeout(() => {
      document
        .getElementsByClassName('copiedMessage')[0]
        .classList.add('hidden')
      document
        .getElementsByClassName('defaultMessage')[0]
        .classList.remove('hidden')
    }, 500)
  }

  return (
    <section className="WinnerCards StoryCards" data-testid="story-cards-slice">
      <div className="Container">
        <div className="WinnerCards___header">
          <PrismicRichText field={slice.primary.section_title?.richText} />
          <PrismicRichText
            field={slice.primary.section_description?.richText}
          />
        </div>
        <div className="WinnerCards___card-row">
          {slice.items.map((item, index) => (
            <div className="WinnerCards___card" key={slice.id + index}>
              <button
                type="button"
                className="copyToClipboard"
                onClick={() => handleCopyText(item)}
                aria-live="polite"
              >
                <span className="defaultMessage" cursor="pointer">
                  Copy Story to Clipboard
                </span>
                <span className="copiedMessage hidden">Copied!</span>
              </button>
              {hasImage(item.card_image)}
              <div className="WinnerCards___text">
                <PrismicRichText field={item.name.richText} />
                <PrismicRichText field={item.main_details.richText} />
                <div
                  className={`readMoreContainer ${expandedContent[index] ? 'expanded' : ''}`}
                >
                  <div className="readMoreContent">
                    <PrismicRichText field={item.bio.richText} />
                  </div>
                  <button
                    type="button"
                    className="readMoreButton"
                    onClick={() => toggleContent(index)}
                  >
                    <strong>
                      {expandedContent[index] ? 'Read Less' : 'Read More'}
                    </strong>
                  </button>
                </div>
                <PrismicRichText
                  field={item.external_links.richText}
                  components={{
                    hyperlink: ({ children, node }) => (
                      <a
                        href={node.data.url}
                        target={node.data.target}
                        rel={
                          node.data.target === '_blank'
                            ? 'noopener noreferrer'
                            : undefined
                        }
                        className='underline'
                      >
                        {children}
                      </a>
                    ),
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment SongwriterAiPageDataBodyStoryCard on PrismicSongwriterAiPageDataBodyStoryCard {
    id
    primary {
      section_title {
        richText
      }
      section_description {
        richText
      }
    }
    items {
      card_image {
        gatsbyImageData
        alt
      }
      name {
        richText
      }
      main_details {
        richText
      }
      bio {
        richText
      }
      external_links {
        richText
      }
    }
  }
`
