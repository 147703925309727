exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thankYou.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-judges-js": () => import("./../../../src/templates/judges.js" /* webpackChunkName: "component---src-templates-judges-js" */),
  "component---src-templates-module-lesson-js": () => import("./../../../src/templates/module_lesson.js" /* webpackChunkName: "component---src-templates-module-lesson-js" */),
  "component---src-templates-module-overview-js": () => import("./../../../src/templates/module_overview.js" /* webpackChunkName: "component---src-templates-module-overview-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-songwriter-ai-hub-js": () => import("./../../../src/templates/songwriter_ai_hub.js" /* webpackChunkName: "component---src-templates-songwriter-ai-hub-js" */),
  "component---src-templates-songwriter-ai-page-js": () => import("./../../../src/templates/songwriter_ai_page.js" /* webpackChunkName: "component---src-templates-songwriter-ai-page-js" */),
  "component---src-templates-teacher-checklist-js": () => import("./../../../src/templates/teacher_checklist.js" /* webpackChunkName: "component---src-templates-teacher-checklist-js" */),
  "component---src-templates-teacher-training-js": () => import("./../../../src/templates/teacher_training.js" /* webpackChunkName: "component---src-templates-teacher-training-js" */),
  "component---src-templates-winners-js": () => import("./../../../src/templates/winners.js" /* webpackChunkName: "component---src-templates-winners-js" */)
}

