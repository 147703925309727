import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicLink } from '@prismicio/react'

export const Button = ({ slice }) => {
  return (
    <section className="button">
      <PrismicLink
        href={slice.primary.button_link?.url}
        className="button_primary"
      >
        {slice.primary.button_text}
      </PrismicLink>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyButton on PrismicPageDataBodyButton {
    primary {
      button_text
      button_link {
        url
      }
    }
  }
  fragment SongwriterAiPageDataBodyButton on PrismicSongwriterAiPageDataBodyButton {
    primary {
      button_text
      button_link {
        url
      }
    }
  }
  fragment SongwriterAiHubDataBodyButton on PrismicSongwriterAiHubDataBodyButton {
    primary {
      button_text
      button_link {
        url
      }
    }
  }
`
