import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { LyricsGenerator } from '../components/LyricsGenerator'

export const LyricsGeneratorSlice = ({ slice }) => {
  return (
    <section className="text_section">
      <div className="text_box">
        <PrismicRichText field={slice.primary.title.richText} />
        <PrismicRichText field={slice.primary.description.richText} />
        <LyricsGenerator />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyLyricsGenerator on PrismicPageDataBodyLyricsGenerator {
    primary {
      title {
        richText
      }
      description {
        richText
      }
    }
  }
  fragment SongwriterAiPageDataBodyLyricsGenerator on PrismicSongwriterAiPageDataBodyLyricsGenerator {
    primary {
      title {
        richText
      }
      description {
        richText
      }
    }
  }
  fragment SongwriterAiHubDataBodyLyricsGenerator on PrismicSongwriterAiHubDataBodyLyricsGenerator {
    primary {
      title {
        richText
      }
      description {
        richText
      }
    }
  }
`
