import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const WinnersTemplate = ({ data }) => {
  const winnersContent = data.prismicWinners
  const winners = winnersContent.data || {}
  const display_title = winners.display_title?.text || ''
  const { lang, type, url } = winnersContent
  const alternateLanguages = winnersContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    display_title,
  }
  const topMenu = data.prismicTopMenu || {}
  const bottomMenu = data.prismicBottomMenu || {}

  return (
    <Layout
      topMenu={topMenu.data}
      bottomMenu={bottomMenu.data}
      activeDocMeta={activeDoc}
    >
      <div className="WinnersPage">
        {winners.hero_content?.richText.length > 0 && (
          <div className="Container">
            <div className="WinnersPage___copy">
              <PrismicRichText field={winners.hero_content?.richText} />
            </div>
          </div>
        )}
        <SliceZone slices={winners.body} components={components} />
        <div className="Container">
          <div className="btn-container">
            {winners.link_label_1 && (
              <PrismicLink
                className="button_primary"
                href={winners.link_1?.url || '#'}
              >
                {winners.link_label_1}
              </PrismicLink>
            )}
            {winners.link_label_2 && (
              <PrismicLink
                className="button_secondary"
                href={winners.link_2?.url || '#'}
              >
                {winners.link_label_2}
              </PrismicLink>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query winnersQuery($id: String, $lang: String) {
    prismicWinners(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        hero_content {
          richText
        }
        link_label_1
        link_1 {
          url
        }
        link_label_2
        link_2 {
          url
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...WinnersDataBodyWinnerCards
        }
        display_title {
          text
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicBottomMenu(lang: { eq: $lang }) {
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(WinnersTemplate)
