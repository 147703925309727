import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const SongwriterAiPageTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicSongwriterAiPage
  const page = pageContent.data || {}
  const display_title = page.display_title?.text || ''
  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    display_title,
  }
  const topMenu = data.prismicTopMenu || {}
  const bottomMenu = data.prismicBottomMenu || {}

  return (
    <Layout
      topMenu={topMenu.data}
      bottomMenu={bottomMenu.data}
      activeDocMeta={activeDoc}
    >
      <div className="Container">
        {page.title?.richText.length > 0 && (
          <div className="Container">
            <div className="WinnersPage___copy">
              <PrismicRichText field={page.title?.richText} />
            </div>
          </div>
        )}
        <SliceZone
          slices={page.body.map((slice) => ({
            ...slice,
            pageType: type,
          }))}
          components={components}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query songwriter_ai_pageQuery($id: String, $lang: String) {
    prismicSongwriterAiPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          richText
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...SongwriterAiPageDataBodyTextSection
          ...SongwriterAiPageDataBodyButton
          ...SongwriterAiPageDataBodyCurriculumSection
          ...SongwriterAiPageDataBodyLyricsGenerator
          ...SongwriterAiPageDataBodyHero
          ...SongwriterAiPageDataBodyVideoAndText
          ...SongwriterAiPageDataBodyTheArtists
          ...SongwriterAiPageDataBodyHowItWorks
          ...SongwriterAiPageDataBodyCourseOutline
          ...SongwriterAiPageDataBodyEmphasisSection
          ...SongwriterAiPageDataBodyStoryCard
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicBottomMenu(lang: { eq: $lang }) {
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(SongwriterAiPageTemplate)
