import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const SongwriterAiHubTemplate = ({ data }) => {
  if (!data) return null

  const songwriterAiHubContent = data.prismicSongwriterAiHub
  const hubContent = songwriterAiHubContent.data || {}

  const { lang, type, url } = songwriterAiHubContent
  const display_title = hubContent.display_title?.text || ''
  const alternateLanguages = songwriterAiHubContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    display_title,
  }
  const topMenu = data.prismicTopMenu || {}
  const bottomMenu = data.prismicBottomMenu || {}

  return (
    <Layout
      topMenu={topMenu.data}
      bottomMenu={bottomMenu.data}
      activeDocMeta={activeDoc}
    >
      <div className="Container page_template">
        {hubContent.title.richText.length > 0 && (
          <div className="title">
            <PrismicRichText field={hubContent.title.richText} />
          </div>
        )}
        <SliceZone slices={hubContent.body} components={components} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query songwriter_ai_hubQuery($id: String, $lang: String) {
    prismicSongwriterAiHub(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          richText
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...SongwriterAiHubDataBodyHero
          ...SongwriterAiHubDataBodyTextSection
          ...SongwriterAiHubDataBodyEmphasisSection
          ...SongwriterAiHubDataBodyButton
          ...SongwriterAiHubDataBodyLyricsGenerator
          ...SongwriterAiHubDataBodyCurriculumSection
          ...SongwriterAiHubDataBodyVideoAndText
          ...SongwriterAiHubDataBodyTheArtists
          ...SongwriterAiHubDataBodyHowItWorks
          ...SongwriterAiHubDataBodyCourseOutline
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicBottomMenu(lang: { eq: $lang }) {
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(SongwriterAiHubTemplate)
