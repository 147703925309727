import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export const Hero = ({ slice }) => {
  const { pageType } = slice
  const image = slice.primary.image
  const isSongwriterPage = pageType === 'songwriter_ai_page'

  return (
    <section className={isSongwriterPage ? 'songwriter-hero' : 'hero'}>
      <div className="Container">
        <div className="flex_container">
          <div className="flex_content">
            <PrismicRichText field={slice.primary.header.richText} />
            <PrismicRichText field={slice.primary.text.richText} />
            {slice.primary.button_text !== null && (
              <PrismicLink
                className="button_primary"
                target={slice.primary.button.target}
                href={slice.primary.button?.url}
              >
                {slice.primary.button_text}
              </PrismicLink>
            )}
          </div>
          <GatsbyImage
            image={image?.gatsbyImageData}
            placeholder="blurred"
            alt={image?.alt || ''}
            className="hero_image"
            loading="eager"
            objectFit="contain"
          />
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHero on PrismicHomepageDataBodyHero {
    primary {
      image {
        url
        gatsbyImageData(width: 445, height: 562, placeholder: BLURRED)
        alt
        dimensions {
          height
          width
        }
      }
      header {
        richText
      }
      text {
        richText
      }
      button {
        document {
          ... on PrismicModuleLesson {
            id
            url
            type
          }
        }
        url
        target
      }
      button_text
    }
  }
  fragment SongwriterAiPageDataBodyHero on PrismicSongwriterAiPageDataBodyHero {
    primary {
      image {
        url
        gatsbyImageData(width: 445, height: 562, placeholder: BLURRED)
        alt
        dimensions {
          height
          width
        }
      }
      header {
        richText
      }
      text {
        richText
      }
      button {
        document {
          ... on PrismicModuleLesson {
            id
            url
            type
          }
        }
        url
        target
      }
      button_text
    }
  }
  fragment SongwriterAiHubDataBodyHero on PrismicSongwriterAiHubDataBodyHero {
    primary {
      image {
        url
        gatsbyImageData(width: 445, height: 562, placeholder: BLURRED)
        alt
        dimensions {
          height
          width
        }
      }
      header {
        richText
      }
      text {
        richText
      }
      button {
        document {
          ... on PrismicModuleLesson {
            id
            url
            type
          }
        }
        url
        target
      }
      button_text
    }
  }
`
